import { IonContent, IonPage } from '@ionic/react';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import 'whatwg-fetch';
import ArrowBackIcon from '../../assets/arrow-back.svg';
import LogoPergunteAlbert from '../../assets/chat.png';
import LoadingIcon from '../../assets/loading.gif';
import LogoWhiteSimple from '../../assets/logo-white-simple.svg';
import SendIcon from '../../assets/send.svg';
import useAuth from '../../hooks/useAuth';
import { chatInit, getChat } from '../../services/chat';
import { checkLimit } from '../../services/contracts';
import { LIMIT_TYPE } from '../Promotions/types';
import { useMessages } from './ChatContext';
require('dotenv').config();
const isProduction = process.env.REACT_APP_ENV === "production"
interface Message {
  role: 'user' | 'assistant' | 'warning';
  content: string;
  timestamp: string;
}

const convertMarkdownToHtml = (markdown: string) => {
  let html = markdown
    .replace(/(\*\*|__)(.*?)\1/g, '<b>$2</b>') // Negrito
    .replace(/(\*|_)(.*?)\1/g, '<i>$2</i>') // Itálico
    .replace(/\n/g, '<br />') // Quebra de linha
    .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>') // Links
    .replace(/^\s*\n\*/gm, '<ul>\n*') // Listas não ordenadas
    .replace(/^(\*.+)\s*\n([^\*])/gm, '$1\n</ul>\n\n$2') // Fechamento de lista não ordenada
    .replace(/^\*(.+)/gm, '<li>$1</li>'); // Item de lista não ordenada

  // Conversão de tabelas Markdown para HTML
  const tableMarkdown = markdown.match(/^\|.*\|$/gm);
  if (tableMarkdown) {
    const [header, separator, ...rows] = tableMarkdown;
    if (separator && separator.match(/^\|\s*[-]+\s*\|/)) {
      const thead = `<thead class="thead bg-gray-100"><tr>${header
        .split('|')
        .map(
          (col) =>
            `<th class="text-left text-xs font-medium text-gray-500 uppercase">${col.trim()}</th>`
        )
        .join('')}</tr></thead>`;
      const tbody = rows
        .map(
          (row) =>
            `<tr class="text-sm text-gray-500">${row
              .split('|')
              .map((col) => `<td>${col.trim()}</td>`)
              .join('')}</tr>`
        )
        .join('');
      html = html.replace(
        tableMarkdown.join('<br />'),
        `<table class="table min-w-full divide-y divide-gray-200">${thead}<tbody class="bg-white divide-y divide-gray-200">${tbody}</tbody></table>`
      );
    }
  }

  return html;
};

const PergunteAlbertAIChat: React.FC = () => {
  const { message: initialMessage, chat } = useMessages();
  const { company, token } = useAuth();
  const history = useHistory();
  const [chatId, setChatId] = useState<string>();

  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [textareaHeight, setTextareaHeight] = useState<number>(36);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const [premium, setPremium] = useState<boolean>(false);
  const [inputVisible, setInputVisible] = useState<boolean>(true);

  const handleCheckLimitChat = useCallback(async () => {
    try {
      if (!company) return;

      const responsePremium = await checkLimit(LIMIT_TYPE.ai_premium);

      setPremium(responsePremium?.limit > 0);
    } catch (error) {}
  }, [company]);

  useEffect(() => {
    (async () => {
      await handleCheckLimitChat();
    })();
  }, [handleCheckLimitChat]);

  const sendMessage = async (
    option?: string,
    prompt_id?: string,
    response_prompt?: string,
    chat?: string
  ) => {
    if (input.trim() === '' && !option) return;

    const newMessages: Message[] = [
      ...messages,
      {
        role: 'user',
        content: option || input,
        timestamp: new Date().toISOString(),
      },
    ];

    setMessages(newMessages);
    setInput('');
    setLoading(true);
    setTextareaHeight(36);

    if (response_prompt) {
      let assistantMessage: Message = {
        role: 'assistant',
        content: '',
        timestamp: new Date().toISOString(),
      };
      const newAssistantMessages = [...newMessages, assistantMessage];
      setMessages(newAssistantMessages);

      const reader = (async function* () {
        const textArray = response_prompt.split(' ');
        for (let i = 0; i < textArray.length; i++) {
          yield textArray[i] + ' ';
          await new Promise((resolve) => setTimeout(resolve, 50)); // Ajuste o tempo conforme necessário
        }
      })();

      const readStream = async () => {
        let assistantMessage: Message = {
          role: 'assistant',
          content: '',
          timestamp: new Date().toISOString(),
        };

        for await (const chunk of reader) {
          if (chunk) {
            assistantMessage.content += chunk;
            setMessages([...newMessages, { ...assistantMessage }]);
          }
        }
      };

      readStream();
      setLoading(false);
    } else {
      try {
        const response = await fetch(
          //`${process.env.REACT_APP_API_URL}/v2/partners/ai/chat/${chatId}/send`,
          //'http://localhost:3333/api/v1/partners/chat',
          //`http://staging-alb-api-8066955.us-east-1.elb.amazonaws.com:3000/api/v2/partners/ai/chat/${chat?chat:chatId}/send`,
          //(prompt_id ? `http://staging-alb-api-8066955.us-east-1.elb.amazonaws.com:3000/api/v2/partners/ai/prompts/${prompt_id}/send` : `http://staging-alb-api-8066955.us-east-1.elb.amazonaws.com:3000/api/v2/partners/ai/chat/${chat?chat:chatId}/send`),
          prompt_id
            ? `https://chat.${isProduction ? "oialbert.com.br" : "albertbox.com.br"}/api/v2/partners/ai/prompts/${prompt_id}/send`
            : `https://chat.${isProduction ? "oialbert.com.br" : "albertbox.com.br"}/api/v2/partners/ai/chat/${chatId}/send`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              company_id: `${company?.id}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              chat_id: chat ? chat : chatId,
              message: option || input,
            }),
          }
        );

        if (!response.body) throw new Error('Corpo de resposta vazio');

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let assistantMessage: Message = {
          role: 'assistant',
          content: '',
          timestamp: new Date().toISOString(),
        };
        const newAssistantMessages = [...newMessages, assistantMessage];
        setMessages(newAssistantMessages);

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.split('\n').filter((line) => line.trim() !== '');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = line.replace('data: ', '');
              if (data === '[DONE]') break;
              try {
                const json = JSON.parse(data);
                if (json.choices && json.choices[0] && json.choices[0].delta) {
                  const delta = json.choices[0].delta.content;
                  if (delta) {
                    assistantMessage.content += delta;
                    setMessages([...newMessages, { ...assistantMessage }]);
                  }
                }
              } catch (e) {
                console.error('Erro ao processar chunk:', e);
              }
            }
          }
        }

        if (response.status === 403) {
          assistantMessage.role = 'warning';
          assistantMessage.content +=
            'O limite para enviar mensagens foi atingido';
          setMessages([...newMessages, { ...assistantMessage }]);
          setInputVisible(false);
        }
      } catch (error) {
        let assistantMessageError: Message = {
          role: 'assistant',
          content: 'Erro ao enviar mensagem: ' + error,
          timestamp: new Date().toISOString(),
        };
        const newAssistantMessages = [...newMessages, assistantMessageError];
        setMessages(newAssistantMessages);

        console.error('Erro ao enviar mensagem:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, loading, textareaHeight]);

  const initChat = useCallback(
    async (initialMessage?) => {
      try {
        if (!company?.id) return;

        const data =
          chat !== ''
            ? await getChat(company?.id, chat)
            : await chatInit(company?.id, initialMessage?.prompt_id);
        setChatId(chat !== '' ? data[0]?.id : data.id);

        if (chat !== '') {
          setMessages(
            data[0]?.messages.map((m: any) => {
              return {
                role: m.send_by,
                content: m.content,
                timestamp: m.sent_at,
              };
            })
          );
        } else if (initialMessage) {
          sendMessage(
            initialMessage.content,
            initialMessage?.prompt_id,
            initialMessage.response,
            chat !== '' ? data[0]?.id : data.id
          );
        }
      } catch {
        history.push('/ai/prompts');
      }
    },
    [company]
  );

  useEffect(() => {
    if (!company) return;

    if (initialMessage) {
      initChat(initialMessage);
    } else {
      if (chat === '') {
        setMessages([
          {
            role: 'assistant',
            content: 'Olá! Pergunte ao Albert.',
            timestamp: new Date().toISOString(),
          },
        ]);
      }
      initChat();
    }
  }, [company]);

  const formatDate = (dateString: string): string => {
    return format(parseISO(dateString), 'dd/MM/yyyy');
  };

  const formatTime = (dateString: string): string => {
    return format(parseISO(dateString), 'HH:mm');
  };

  const groupMessagesByDay = (
    messages: Message[]
  ): { [date: string]: Message[] } => {
    return messages.reduce((acc, message) => {
      const date = formatDate(message.timestamp);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {} as { [date: string]: Message[] });
  };

  const groupedMessages = groupMessagesByDay(messages);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, scrollHeight } = e.target;
    setInput(value);

    const minHeight = 36;
    const maxHeight = window.innerHeight * 0.4;

    const newHeight = Math.min(maxHeight, Math.max(minHeight, scrollHeight));

    setTextareaHeight(newHeight);
  };

  return (
    <IonPage className="overflow-hidden">
      <IonContent>
        <div className="flex flex-col items-center min-h-screen bg-white normal-case">
          <div className="w-full bg-white fixed">
            <div className="absolute">
              <button
                className="mr-2 m-3 flex"
                onClick={() => history.push('/ai/prompts')}
              >
                <img
                  src={ArrowBackIcon}
                  alt="Voltar"
                  className="w-6 h-6 m-1.5"
                />
                <span className="text-lg text-white font-semibold hidden md:inline normal-case block mt-1.5">
                  Pergunte ao Albert
                </span>
              </button>
            </div>

            <div
              className="flex items-center justify-center bg-neon-900 text-white p-4"
              style={{ height: '62px' }}
            >
              <img src={LogoWhiteSimple} alt="Logo" />
            </div>
          </div>

          <div
            className="chat w-full bg-white flex flex-col overflow-y-auto fixed"
            style={{ top: '62px', bottom: `${90 + textareaHeight}px` }}
          >
            <div className="bg-white w-full max-w-2xl p-4 mx-auto">
              <div style={{ height: `calc(100% - 56px)` }}>
                {Object.keys(groupedMessages).map((date) => (
                  <div key={date} className="pb-4">
                    <div className="flex items-center justify-center text-center mt-2">
                      <img
                        src={LogoPergunteAlbert}
                        width={70}
                        alt="Chat Logo"
                      />
                    </div>
                    <div className="text-center my-2">
                      <span className="bg-white text-xs normal-case text-gray-400">
                        Hoje
                      </span>
                    </div>
                    {groupedMessages[date].map((message, index) => (
                      <div key={index}>
                        <div
                          className={` ${
                            message.role === 'user' ? 'text-right' : 'text-left'
                          } mb-1 text-xs text-gray-500`}
                        >
                          {formatTime(message.timestamp)}
                        </div>
                        <div
                          style={
                            message.role === 'user'
                              ? {
                                  borderRadius: '24px',
                                  borderTopRightRadius: '5px',
                                }
                              : {
                                  borderRadius: '24px',
                                  borderTopLeftRadius: '5px',
                                }
                          }
                          className={`font-inter text-sm font-normal p-3 mb-2 max-w-lg
                                                        ${
                                                          message.role ===
                                                          'user'
                                                            ? 'rounded-bl-full rounded-br-full rounded-tr-lg rounded-tl-full bg-gray-900 text-white self-end ml-auto'
                                                            : 'rounded-r-lg rounded-l-lg rounded-lg self-start mr-auto'
                                                        }
                                                        ${
                                                          message.role ===
                                                            'assistant' &&
                                                          'bg-gray-300 text-gray-800'
                                                        }
                                                        ${
                                                          message.role ===
                                                            'warning' &&
                                                          'bg-yellow-500 text-warning-dark'
                                                        }`}
                          dangerouslySetInnerHTML={{
                            __html: convertMarkdownToHtml(
                              message.content ?? ''
                            ),
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div ref={messagesEndRef} />
          </div>

          {premium && inputVisible && (
            <div className="flex flex-col px-4 items-center w-full bg-gray-100 fixed bottom-0">
              {loading && (
                <div
                  style={{
                    marginLeft: '2.5rem',
                    marginRight: '2.5rem',
                    width: 'calc(100% - 2.5rem)',
                  }}
                  className="w-full max-w-2xl text-gray-600 font-inter text-xs font-normal flex absolute top-4"
                >
                  Digitando{' '}
                  <img className="ml-2" width="40" src={LoadingIcon} />
                </div>
              )}
              <div className="w-full m-10 bg-white max-w-2xl flex p-1 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-neon-900 relative">
                <textarea
                  value={input}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  disabled={loading}
                  style={{ height: `${textareaHeight}px`, marginRight: '37px' }}
                  className="flex-1 p-2 resize-none overflow-y-auto text-sm normal-case focus:outline-none"
                  placeholder={loading ? `` : `Pergunte algo para começar`}
                />
                <button
                  onClick={() => sendMessage()}
                  className="p-2 bg-neon-900 text-white rounded-lg hover:bg-neon-900 disabled:opacity-50 absolute right-1 bottom-1"
                  style={{ height: '34px' }}
                  disabled={loading}
                >
                  <img src={SendIcon} />
                </button>
              </div>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PergunteAlbertAIChat;
